@import "~bootstrap/scss/bootstrap";
.nav * {
    color: rgb(248, 249, 250) !important;
}
.dropdown-item:hover {
    background-color: rgb(247, 247, 247) !important;
}
.login-btn{
    color: white !important;
    text-decoration: none !important;
}
.login-btn:hover{
    color: white !important;
    text-decoration: none !important;
}